import React, { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { toast } from "@/components/ui/use-toast";
import { Checkbox } from "@/components/ui/checkbox";
import { useTranslation } from "react-i18next";
import { ImageTag, useImageTags } from "@/api/tags";

function ManageImageTags({ trigger }: { trigger: JSX.Element }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [caseTags, setTags] = useState<ImageTag[]>([]);
  const { values: contextValues, set } = useContext(
    AuthContext,
  ) as AuthContextType;
  const errorHandler = useApiErrorHandler("AddEditTags", {});
  const { loading } = contextValues;
  const { setLoading } = set;

  const {
    list: caseTagsList,
    refresh: refreshCaseTagsList,
    updateImageTag,
  } = useImageTags();

  useEffect(() => {
    setTags(caseTagsList);
  }, [caseTagsList]);

  async function onSubmit() {
    try {
      setLoading?.(true);
      await Promise.all(
        caseTags.map((item) =>
          updateImageTag(item.id, item.default, item.display_name),
        ),
      );
      toast({
        title: t("common.toast.updated_successfully", {
          resource: t("common.label.tag"),
        }),
      });
      await refreshCaseTagsList();
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading?.(false);
    }
  }

  function onCancel() {
    setOpen(false);
  }

  const onCheckboxChange = useCallback(
    (checked: boolean, tagId: number) => {
      let tempTags = Object.assign([], caseTags) as ImageTag[];
      tempTags = tempTags.map((item) => {
        if (item.id === tagId) {
          return {
            ...item,
            default: checked,
          };
        } else {
          return item;
        }
      });
      setTags(tempTags);
    },
    [caseTags],
  );

  const onTagValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, tagId: number) => {
      let tempTags = Object.assign([], caseTags) as ImageTag[];
      tempTags = tempTags.map((item) => {
        if (item.id === tagId) {
          return {
            ...item,
            display_name: e.target.value,
          };
        } else {
          return item;
        }
      });
      setTags(tempTags);
    },
    [caseTags],
  );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent
        className="w-full md:w-[500px] max-h-screen overflow-y-auto"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        onEscapeKeyDown={(e) => e.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle>{t("cases.title.edit_default_tags")}</DialogTitle>
        </DialogHeader>

        <table>
          {caseTags?.map((item) => (
            // eslint-disable-next-line react/jsx-key
            <tr>
              <td>
                <Checkbox
                  checked={item.default}
                  onCheckedChange={(checked: boolean) =>
                    onCheckboxChange(checked, item.id)
                  }
                />
              </td>
              <td>
                <img
                  className="w-[32px]"
                  alt={item.display_name || item.name}
                  src={item.icon?.replace(
                    "http://127.0.0.1:8001",
                    process.env.REACT_APP_BACKEND_URL || "",
                  )}
                />
              </td>
              <td>
                <Input
                  type="text"
                  value={item.display_name || ""}
                  onChange={(e) => onTagValueChange(e, item.id)}
                />
              </td>
            </tr>
          ))}
        </table>
        <DialogFooter>
          <Button variant="secondary" disabled={loading} onClick={onCancel}>
            {loading ? t("common.button.loading") : t("common.button.close")}
          </Button>
          <Button variant="default" disabled={loading} onClick={onSubmit}>
            {loading ? t("common.button.loading") : t("common.button.confirm")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default ManageImageTags;
