import React, { useContext, useState } from "react";
import { Button } from "@/components/ui/button";
import CaseFolderTable from "@/components/custom/Cases/CaseFolderTable";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { legacyNotesApi } from "@/api/notes";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { legacyCommunicationsApi } from "@/api/communications";
import { AddUpdateCivilCase } from "../Cases/AddUpdateCivilCase";
import { AddUpdateCriminalCase } from "../Cases/AddUpdateCriminalCase";
import { AddUpdateCaseSource } from "../Sources/AddUpdateCaseSource";
import { useAdminPermission, usePermission } from "@/hooks/usePermission";
import { InLineLoader } from "@/components/ui/loader";
import { useListApi } from "@/hooks/useListApi";
import { Case, casesApi } from "@/api/cases";
import { useTranslation } from "react-i18next";
import ManageImageTags from "../ImageTags/ManageImageTags";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const FileDownload = require("js-file-download");

function Home() {
  const { t } = useTranslation();
  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const { user } = contextValues;
  const [openSourceCreate, setOpenSourceCreate] = useState<boolean>(false);
  const [loadingNotes, setLoadingNotes] = useState<boolean>(false);
  const [loadingCommunication, setLoadingCommunication] =
    useState<boolean>(false);
  const [openCivilCaseCreate, setCivilCaseOpenCreate] =
    useState<boolean>(false);
  const [openCriminalCaseCreate, setCriminalCaseOpenCreate] =
    useState<boolean>(false);
  const hasAdminPermission = useAdminPermission();
  const hasAddSourcePermission = usePermission("review.add_source");
  const hasAddCasePermission = usePermission("review.add_case");

  const { loading, list, refresh, search, loadNext } = useListApi<Case>({
    baseApiObject: casesApi,
    defaultPageSize: 100,
    enable: true,
    defaultParams: {
      archived: 0,
    },
  });

  const errorHandler = useApiErrorHandler("Home", {});

  const handleDownloadAllNotes = async () => {
    try {
      setLoadingNotes(true);
      const response = await legacyNotesApi.downloadAllNotes({
        token: user?.access,
      });
      FileDownload(response.data, "notes.pdf");
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingNotes(false);
    }
  };

  const handleDownloadAllCommunications = async () => {
    try {
      setLoadingCommunication(true);
      const response = await legacyCommunicationsApi.downloadAllCommunications({
        token: user?.access,
      });
      FileDownload(response.data, "communications.pdf");
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingCommunication(false);
    }
  };

  return (
    <div className="flex flex-col text-center justify-center w-full items-center">
      <h3 className="text-3xl font-bold mb-4">{t("common.title.cases")}</h3>
      <div className="flex flex-col md:flex-row justify-between w-full gap-4 mb-8 md:mb-0">
        <div className="flex flex-col md:flex-row flex-wrap gap-4 w-full md:w-[30%] justify-start">
          {hasAddCasePermission && (
            <>
              <AddUpdateCriminalCase
                trigger={
                  <Button className="text-sm md:text-md">
                    {t("home.button.add_criminal_case")}
                  </Button>
                }
                open={openCriminalCaseCreate}
                setOpen={setCriminalCaseOpenCreate}
                refresh={refresh}
              />
              <AddUpdateCivilCase
                trigger={<Button>{t("home.button.add_civil_case")}</Button>}
                open={openCivilCaseCreate}
                setOpen={setCivilCaseOpenCreate}
                refresh={refresh}
              />
            </>
          )}
          {hasAdminPermission && (
            <>
              <Button onClick={handleDownloadAllNotes} disabled={loadingNotes}>
                {t("home.button.download_all_notes")}
                {loadingNotes && <InLineLoader size={8} />}
              </Button>
              <Button
                onClick={handleDownloadAllCommunications}
                disabled={loadingCommunication}
              >
                {t("home.button.download_all_comms")}
                {loadingCommunication && <InLineLoader size={8} />}
              </Button>
            </>
          )}
        </div>

        <div className="flex flex-col md:flex-row flex-wrap gap-4 w-full md:w-[30%] justify-end">
          {hasAddSourcePermission && (
            <AddUpdateCaseSource
              trigger={<Button>{t("home.button.add_source")}</Button>}
              open={openSourceCreate}
              setOpen={setOpenSourceCreate}
            />
          )}
          {hasAdminPermission && (
            <ManageImageTags
              trigger={<Button>{t("cases.title.edit_default_tags")}</Button>}
            />
          )}
        </div>
      </div>

      <CaseFolderTable
        loading={loading}
        list={list}
        refresh={refresh}
        loadNext={loadNext}
        search={search}
      />
    </div>
  );
}

export default Home;
