/* eslint-disable @bigbinary/neeto/hard-coded-strings-should-be-localized */
type FieldPropertyMapping = {
  [key: string]: string;
};

type TypeInfo = {
  name: string;
  value?: string;
};

type Context = {
  info: (desc: string) => void;
  error: (err: string) => void;
  data: string[];
  currentCard: Record<string, any>;
  cards: Record<string, unknown>[];
};

type FieldParser = (
  context: Context,
  fieldValue: string,
  fieldName: string,
  typeInfo?: TypeInfo[],
) => void;

const vCardParser = (() => {
  const fieldPropertyMapping: FieldPropertyMapping = {
    TITLE: "title",
    TEL: "telephone",
    FN: "displayName",
    N: "name",
    EMAIL: "email",
    CATEGORIES: "categories",
    ADR: "address",
    URL: "url",
    NOTE: "notes",
    ORG: "organization",
    BDAY: "birthday",
    PHOTO: "photo",
  };

  function lookupField(context: Context, fieldName: string): string {
    let propertyName = fieldPropertyMapping[fieldName];

    if (!propertyName && fieldName !== "BEGIN" && fieldName !== "END") {
      context.info("Define property name for " + fieldName);
      propertyName = fieldName;
    }

    return propertyName;
  }

  function removeWeirdItemPrefix(line: string): string {
    return line.startsWith("item")
      ? (line.match(/item\d\.(.*)/)?.[1] ?? line)
      : line;
  }

  function singleLine(
    context: Context,
    fieldValue: string,
    fieldName: string,
  ): void {
    fieldValue = fieldValue.replace(/\\n/g, "\n");

    if (context.currentCard[fieldName]) {
      context.currentCard[fieldName] += "\n" + fieldValue;
    } else {
      context.currentCard[fieldName] = fieldValue;
    }
  }

  function typedLine(
    context: Context,
    fieldValue: string,
    fieldName: string,
    typeInfo: TypeInfo[] = [],
    valueFormatter?: (value: string) => unknown,
  ): void {
    let isDefault = false;

    typeInfo = typeInfo.filter((type) => {
      isDefault = isDefault || type.name === "PREF";
      return type.name !== "PREF";
    });

    const valueInfo = typeInfo.reduce<Record<string, string | undefined>>(
      (acc, type) => {
        acc[type.name] = type.value;
        return acc;
      },
      {},
    );

    context.currentCard[fieldName] = context.currentCard[fieldName] || [];

    context.currentCard[fieldName].push({
      isDefault,
      valueInfo,
      value: valueFormatter ? valueFormatter(fieldValue) : fieldValue,
    });
  }

  function commaSeparatedLine(
    context: Context,
    fieldValue: string,
    fieldName: string,
  ): void {
    context.currentCard[fieldName] = fieldValue.split(",");
  }

  function dateLine(
    context: Context,
    fieldValue: string,
    fieldName: string,
  ): void {
    fieldValue =
      fieldValue.length === 16 ? fieldValue.substr(0, 8) : fieldValue;

    let dateValue: Date | null = null;

    if (fieldValue.length === 8) {
      const year = parseInt(fieldValue.substr(0, 4), 10);
      const month = parseInt(fieldValue.substr(4, 2), 10) - 1;
      const day = parseInt(fieldValue.substr(6, 2), 10);
      dateValue = new Date(year, month, day);
    } else {
      dateValue = new Date(fieldValue);
    }

    if (isNaN(dateValue.getTime())) {
      dateValue = null;
      context.error("Invalid date format " + fieldValue);
    }

    context.currentCard[fieldName] = dateValue ? dateValue.toISOString() : null;
  }

  function structured(fields: string[]): FieldParser {
    return (context, fieldValue, fieldName) => {
      const values = fieldValue.split(";");
      context.currentCard[fieldName] = fields.reduce<Record<string, string>>(
        (acc, field, index) => {
          acc[field] = values[index] || "";
          return acc;
        },
        {},
      );
    };
  }

  function addressLine(
    context: Context,
    fieldValue: string,
    fieldName: string,
    typeInfo?: TypeInfo[],
  ): void {
    typedLine(context, fieldValue, fieldName, typeInfo, (value) => {
      const parts = value.split(";");
      return {
        postOfficeBox: parts[0] || "",
        number: parts[1] || "",
        street: parts[2] || "",
        city: parts[3] || "",
        region: parts[4] || "",
        postalCode: parts[5] || "",
        country: parts[6] || "",
      };
    });
  }

  function noop(): void {}

  function endCard(context: Context): void {
    context.cards.push(context.currentCard);
    context.currentCard = {};
  }

  const fieldParsers: Record<string, FieldParser> = {
    BEGIN: noop,
    VERSION: noop,
    N: structured(["surname", "name", "additionalName", "prefix", "suffix"]),
    TITLE: singleLine,
    TEL: typedLine,
    EMAIL: typedLine,
    ADR: addressLine,
    NOTE: singleLine,
    NICKNAME: commaSeparatedLine,
    BDAY: dateLine,
    URL: singleLine,
    CATEGORIES: commaSeparatedLine,
    END: endCard,
    FN: singleLine,
    ORG: singleLine,
    UID: singleLine,
    PHOTO: singleLine,
  };

  function feedData(context: Context): void {
    for (const line of context.data) {
      const cleanedLine = removeWeirdItemPrefix(line);
      const [rawFieldName, ...fieldValueParts] = cleanedLine.split(":");
      const fieldValue = fieldValueParts.join(":");

      let fieldName = rawFieldName;
      let fieldTypeInfo: TypeInfo[] | undefined;

      if (
        fieldName.includes(";") &&
        cleanedLine.indexOf(";") < cleanedLine.indexOf(":")
      ) {
        const parts = fieldName.split(";");
        fieldName = parts[0];
        fieldTypeInfo = parts.slice(1).map((type) => {
          const [name, value] = type.split("=");
          return {
            name: name.toLowerCase(),
            value: value?.replace(/"(.*)"/, "$1"),
          };
        });
      }

      fieldName = fieldName.toUpperCase();

      const fieldHandler = fieldParsers[fieldName];

      if (fieldHandler) {
        fieldHandler(
          context,
          fieldValue,
          lookupField(context, fieldName),
          fieldTypeInfo,
        );
      } else if (!fieldName.startsWith("X-")) {
        context.info(
          "Unknown field " + fieldName + " with value " + fieldValue,
        );
      }
    }
  }

  function parse(data: string): Record<string, any>[] {
    const lines = data
      .replace(/\n\s{1}/g, "")
      .split(/\r\n(?=\S)|\r(?=\S)|\n(?=\S)/);

    const context: Context = {
      info: console.info,
      error: console.error,
      data: lines,
      currentCard: {},
      cards: [],
    };

    feedData(context);

    return context.cards;
  }

  return { parse };
})();

export default vCardParser;
