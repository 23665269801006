import React from "react";
import vCardParser from "@/lib/vcard-parser";
import { compact } from "lodash";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";

// Define a TypeScript interface for the contact structure
export interface Contact {
  id: number;
  name: string;
  surname: string;
  phone: string;
  email: string;
  organization: string;
  address: string;
  url: string;
}

const getCardJson = (card: Record<string, any>, id: number): Contact | null => {
  try {
    const phoneNumbers =
      card.telephone?.map((tel: Record<string, string>) => tel.value) || [];
    const emailAddresses =
      card.email?.map((email: Record<string, string>) => email.value) || [];
    const addresses =
      card.address?.map((adr: Record<string, Record<string, string>>) => {
        return compact([
          adr.value?.postOfficeBox?.replace(/;/g, ", "),
          adr.value?.number?.replace(/;/g, ", "),
          adr.value?.street?.replace(/;/g, ", "),
          adr.value?.city?.replace(/;/g, ", "),
          adr.value?.region?.replace(/;/g, ", "),
          adr.value?.postalCode?.replace(/;/g, ", "),
          adr.value?.country?.replace(/;/g, ", "),
        ]).join(", ");
      }) || [];

    return {
      id: id,
      name: card.name.name || "",
      surname: card.name.surname || "",
      phone: phoneNumbers?.[0] || "",
      email: emailAddresses?.[0] || "",
      organization: card.organization || "",
      address: addresses?.[0] || "",
      url: card.url || "",
    };
  } catch (error) {
    console.error("Error:getCardJson parsing vCard:", error);
    return null;
  }
};

interface VcardParserForContactProps {
  setContacts: (value: Contact[]) => void;
  fileInputRef: React.MutableRefObject<HTMLInputElement | null>;
  handleClear: () => void;
}

const VcardParserForContact: React.FC<VcardParserForContactProps> = ({
  setContacts,
  fileInputRef,
  handleClear,
}) => {
  const { t } = useTranslation();

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const rawVCardData = e.target?.result as string;
          const cards = vCardParser.parse(rawVCardData);

          const parsedContacts: Contact[] = compact(
            cards.map((card, index) => {
              return getCardJson(card, index);
            }),
          );

          setContacts(parsedContacts);
        } catch (error) {
          console.error("Error handleFileUpload vCard:", error);
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <div className="flex w-full flex-col items-start">
      <Input
        type="file"
        accept=".vcf"
        ref={fileInputRef}
        onChange={handleFileUpload}
      />
      <Button type="button" variant="link" onClick={() => handleClear()}>
        {t("common.button.clear")}
      </Button>
    </div>
  );
};

export default VcardParserForContact;
