import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { AttorneyContact } from "@/api/attorneyContacts";
import { ColumnDef } from "@tanstack/react-table";
import { BsFillPencilFill, BsTrashFill } from "react-icons/bs";
import { DeleteAttorneyContact } from "./DeleteAttorneyContact";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { Contact } from "./UploadVcardForContact";
import JsonDisplay from "../JsonDisplay";
import type { ColumnConfig } from "@/components/ui/EditableTable";

export const useAttorneyContactColumn = ({
  refresh,
  edit,
  setOrder,
}: {
  refresh: () => Promise<void>;
  edit: (item: AttorneyContact) => void;
  setOrder: Dispatch<SetStateAction<string>>;
}) => {
  const { t } = useTranslation();
  const [activeSorting, setActiveSorting] = useState<string>();
  const [isDescendingOrder, setDescendingOrder] = useState<boolean>(false);

  const getSortedValues = useCallback(() => {
    if (activeSorting) {
      return [!isDescendingOrder ? activeSorting : `-${activeSorting}`].join(
        ",",
      );
    } else {
      return "";
    }
  }, [isDescendingOrder, activeSorting]);

  const ordering = getSortedValues();

  useEffect(() => {
    setOrder(ordering);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordering]);

  const columns: ColumnDef<AttorneyContact>[] = [
    {
      accessorKey: "last_name",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setActiveSorting("last_name");
            setDescendingOrder(!isDescendingOrder);
          }}
        >
          {t("common.label.last_name")}{" "}
          <div className="text-xs">
            <IoIosArrowUp
              color={
                activeSorting === "last_name"
                  ? isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
            <IoIosArrowDown
              color={
                activeSorting === "last_name"
                  ? !isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const value = (row.getValue("last_name") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "first_name",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setActiveSorting("first_name");
            setDescendingOrder(!isDescendingOrder);
          }}
        >
          {t("common.label.first_name")}{" "}
          <div className="text-xs">
            <IoIosArrowUp
              color={
                activeSorting === "first_name"
                  ? isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
            <IoIosArrowDown
              color={
                activeSorting === "first_name"
                  ? !isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const value = (row.getValue("first_name") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "email",
      header: () => (
        <div
          className="text-left flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setActiveSorting("email");
            setDescendingOrder(!isDescendingOrder);
          }}
        >
          {t("common.label.email")}{" "}
          <div className="text-xs">
            <IoIosArrowUp
              color={
                activeSorting === "email"
                  ? isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
            <IoIosArrowDown
              color={
                activeSorting === "email"
                  ? !isDescendingOrder
                    ? "#D3D3D3"
                    : "#000000"
                  : "#D3D3D3"
              }
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const value = (row.getValue("email") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "phone",
      header: () => <div className="text-left">{t("common.label.phone")}</div>,
      cell: ({ row }) => {
        const value = (row.getValue("phone") as string) || "--";
        return <div className="text-left">{value}</div>;
      },
    },
    {
      accessorKey: "Actions",
      header: () => (
        <div className="text-left">{t("common.label.commands")}</div>
      ),
      cell: ({ row }) => {
        const attorneyContactItem = row.original;
        return (
          <div className="flex gap-4 text-lg">
            <BsFillPencilFill
              className="hover:text-primary cursor-pointer"
              onClick={() => edit(attorneyContactItem)}
            />
            <DeleteAttorneyContact
              attorneyContact={attorneyContactItem}
              trigger={
                <BsTrashFill className="hover:text-primary cursor-pointer" />
              }
              refresh={refresh}
            />
          </div>
        );
      },
    },
  ];

  return columns;
};

export interface BulkContacts extends Contact {
  id: number;
  success?: boolean;
  error?: string | unknown;
}

export const useAttorneyVCFContactColumn = () => {
  const { t } = useTranslation();

  const columns: ColumnConfig<BulkContacts>[] = [
    {
      key: "surname",
      label: t("common.label.last_name"),
      editable: true,
    },
    {
      key: "name",
      label: t("common.label.first_name"),
      editable: true,
    },
    {
      key: "email",
      label: t("common.label.email"),
      editable: true,
    },
    {
      key: "phone",
      label: t("common.label.phone"),
      editable: true,
    },
    {
      key: "organization",
      label: t("common.label.firm"),
      editable: true,
    },
    {
      key: "address",
      label: t("common.label.address"),
      editable: true,
    },
    {
      key: "url",
      label: t("common.label.url"),
      editable: true,
    },
    {
      key: "id",
      label: "",
      editable: false,
      renderCell: (value, row) => {
        if (row.error) {
          console.error(value, row.error);
        }
        const success = (row.success as boolean) || false;
        const error = (row.error as string) || "";
        const errorComp =
          typeof row.error === "string" ? (
            <p>{error}</p>
          ) : (
            <JsonDisplay data={error} />
          );
        return (
          <div
            className={`text-left ${success === true ? "text-green-600" : "text-red-600"}`}
          >
            {success === true ? (
              <p>
                {t("common.toast.added_successfully", {
                  resource: t("contacts.title.contacts"),
                })}
              </p>
            ) : (
              errorComp
            )}
          </div>
        );
      },
    },
  ];

  return columns;
};
