import React, { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";

export interface ColumnConfig<T> {
  key: keyof T;
  label: string;
  editable?: boolean;
  renderCell?: (value: T[keyof T], row: T) => React.ReactNode; // Custom render method
}

interface EditableTableProps<T> {
  rows: T[];
  setRows: React.Dispatch<React.SetStateAction<T[]>>;
  columns: ColumnConfig<T>[];
  onSave: (updatedData: T[]) => void;
}

const EditableTable = <T extends { id: number }>({
  rows,
  setRows,
  columns,
  onSave,
}: EditableTableProps<T>) => {
  const { t } = useTranslation();

  const [editRowId, setEditRowId] = useState<number | null>(null);
  const [tempRowData, setTempRowData] = useState<Partial<T>>({});

  const handleEdit = (id: number) => {
    setEditRowId(id);
    const rowToEdit = rows.find((row) => row.id === id);
    if (rowToEdit) setTempRowData(rowToEdit);
  };

  const handleSave = (id: number) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, ...tempRowData } : row)),
    );
    setEditRowId(null);
    setTempRowData({});
    onSave(
      rows.map((row) => (row.id === id ? { ...row, ...tempRowData } : row)),
    );
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof T,
  ) => {
    setTempRowData({ ...tempRowData, [field]: e.target.value });
  };

  const handleAddNew = () => {
    const newRow: T = { id: Date.now() } as T;
    columns.forEach((column) => {
      if (column.editable) {
        newRow[column.key] = "" as T[keyof T];
      }
    });
    setRows([...rows, newRow]);
    setEditRowId(newRow.id);
    setTempRowData(newRow);
  };

  const handleRemove = (id: number) => {
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
    onSave(updatedRows);
  };

  return (
    <div className="overflow-y-auto">
      <Button onClick={handleAddNew} className="mb-4">
        {t("common.button.add")}
      </Button>
      <div className="overflow-x-auto">
        <table className="table-auto w-full text-left border border-gray-200">
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={String(column.key)} className="px-4 py-2 border-b">
                  {column.label}
                </th>
              ))}
              <th className="px-4 py-2 border-b">
                {t("common.button.actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.id}>
                {columns.map((column) => (
                  <td key={String(column.key)} className="px-4 py-2 border-b">
                    {column.renderCell ? (
                      column.renderCell(row[column.key], row)
                    ) : editRowId === row.id && column.editable ? (
                      <Input
                        value={(tempRowData[column.key] as string) || ""}
                        onChange={(e) => handleChange(e, column.key)}
                      />
                    ) : (
                      (row[column.key] as string)
                    )}
                  </td>
                ))}
                <td className="px-4 py-2 border-b space-y-2">
                  {editRowId === row.id ? (
                    <Button onClick={() => handleSave(row.id)}>
                      {t("common.button.save")}
                    </Button>
                  ) : (
                    <Button
                      variant="secondary"
                      onClick={() => handleEdit(row.id)}
                    >
                      {t("common.button.edit")}
                    </Button>
                  )}
                  <Button
                    variant="destructive"
                    onClick={() => handleRemove(row.id)}
                  >
                    {t("common.button.remove")}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EditableTable;
