import React, { useContext, useRef, useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { attorneyContactApi } from "@/api/attorneyContacts";
import { useTranslation } from "react-i18next";
import UploadVcardForContact from "./UploadVcardForContact";
import { BulkContacts, useAttorneyVCFContactColumn } from "./hooks";
import axios from "axios";
import Loader from "@/components/ui/loader";
import { z } from "zod";
import EditableTable from "@/components/ui/EditableTable";

export function BulkUploadContacts({
  open,
  setOpen,
  trigger,
  refresh,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  trigger?: JSX.Element;
  refresh?: () => Promise<void>;
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const FormSchema = z.object({
    first_name: z.string().min(2, {
      message: t("common.statictext.first_name_too_short"),
    }),
    last_name: z.string().min(2, {
      message: t("common.statictext.last_name_too_short"),
    }),
    email: z
      .string()
      .min(1, { message: t("common.statictext.field_required") })
      .email(t("common.statictext.invalid_email")),
    phone: z.string(),
    firm: z.string().optional(),
    address: z.string().optional(),
    url: z.string().optional(),
  });

  const { values: contextValues } = useContext(AuthContext) as AuthContextType;
  const [contacts, setContacts] = useState<BulkContacts[]>([]);
  const [rows, setRows] = useState<BulkContacts[]>([]);
  const { user } = contextValues;
  const errorHandler = useApiErrorHandler("AddUpdateAttorneyContact", {});
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleClear = () => {
    setContacts([]);
    setRows([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the file input's value
    }
  };

  const columns = useAttorneyVCFContactColumn();

  async function onSubmit() {
    try {
      setLoading(true);

      const responses = await Promise.all(
        contacts.map(async (item) => {
          try {
            const dataItem = {
              first_name: item.name,
              last_name: item.surname,
              email: item.email,
              phone: item.phone,
              firm: item.organization,
              address: item.address,
              url: item.url,
            };

            // validate data
            FormSchema.parse(dataItem);

            // API Call to server
            await attorneyContactApi.create({
              url: "",
              data: {
                ...dataItem,
                created_by: user?.user.id,
              },
              token: user?.access,
            });
            return { success: true };
          } catch (error) {
            // Type-safe error handling for Zod validation errors
            if (error instanceof z.ZodError) {
              console.error("Validation failed:", error.errors);
              return {
                error: error.errors.reduce((acc, errorItem) => {
                  acc = {
                    ...acc,
                    [errorItem.path.join(", ")]: errorItem.message,
                  };
                  return acc;
                }, {}),
              };
            } else if (
              axios.isAxiosError(error) &&
              error.response?.status &&
              error.response?.status === 400 &&
              error.response?.data
            ) {
              return {
                error: error.response.data,
              };
            } else {
              console.error(
                "BulkUploadContacts onSubmit Api failed:",
                item,
                error,
              );
              return {
                error: t("common.toast.something_went_wrong"),
              };
            }
          }
        }),
      );
      await refresh?.();
      const updatedContacts = contacts.map((item, index) => ({
        ...item,
        ...responses[index],
      }));
      setContacts(updatedContacts);
      setRows(updatedContacts);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }

  const handleSave = (updatedData: BulkContacts[]) => {
    setContacts(updatedData);
    setRows(updatedData);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(openValue) => {
        handleClear();
        setOpen(openValue);
      }}
    >
      {!!trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        className="min-w-[90%] max-h-screen overflow-x-auto"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        onEscapeKeyDown={(e) => e.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle>{t("contacts.button.upload_bulk_contact")}</DialogTitle>
        </DialogHeader>

        <UploadVcardForContact
          setContacts={(value) => {
            setContacts(value);
            setRows(value);
          }}
          fileInputRef={fileInputRef}
          handleClear={handleClear}
        />

        <EditableTable<BulkContacts>
          rows={rows}
          setRows={setRows}
          columns={columns}
          onSave={handleSave}
        />

        <DialogFooter>
          <Button
            disabled={loading || !contacts.length}
            type="button"
            onClick={() => onSubmit()}
          >
            {loading ? <Loader /> : t("common.button.add")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
