import React, { useContext, useEffect, useRef } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormSetError } from "react-hook-form";
import * as z from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast } from "@/components/ui/use-toast";
import { useApiErrorHandler } from "@/lib/errorHandler";
import { AuthContext, AuthContextType } from "@/context/AuthContext";
import { AttorneyContact, attorneyContactApi } from "@/api/attorneyContacts";
import { zodKeys } from "@/lib/utils";

import { PhoneInput } from "@/components/ui/phone-input";
import { useTranslation } from "react-i18next";
import UploadVcardForContact, { type Contact } from "./UploadVcardForContact";

export function AddUpdateAttorneyContact({
  open,
  setOpen,
  attorneyContact,
  trigger,
  refresh,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  attorneyContact?: AttorneyContact;
  trigger?: JSX.Element;
  refresh?: () => Promise<void>;
}) {
  const { t } = useTranslation();
  const { values: contextValues, set } = useContext(
    AuthContext,
  ) as AuthContextType;

  const { loading, user } = contextValues;
  const { setLoading } = set;

  const FormSchema = z.object({
    first_name: z.string().min(2, {
      message: t("common.statictext.first_name_too_short"),
    }),
    last_name: z.string().min(2, {
      message: t("common.statictext.last_name_too_short"),
    }),
    email: z
      .string()
      .min(1, { message: t("common.statictext.field_required") })
      .email(t("common.statictext.invalid_email")),
    phone: z.string(),
    firm: z.string().optional(),
    address: z.string().optional(),
    url: z.string().optional(),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  const errorHandler = useApiErrorHandler("AddUpdateAttorneyContact", {
    setFormError: form.setError as UseFormSetError<Record<string, unknown>>,
  });

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleClear = () => {
    form.reset();
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the file input's value
    }
  };

  const handleSetContact = (value: Contact[]) => {
    const firstContact = value?.[0];
    if (firstContact) {
      form.setValue("first_name", firstContact.name, {
        shouldTouch: true,
      });
      form.setValue("last_name", firstContact.surname, {
        shouldTouch: true,
      });
      form.setValue("email", firstContact.email, {
        shouldTouch: true,
      });
      form.setValue("phone", firstContact.phone, {
        shouldTouch: true,
      });
      form.setValue("firm", firstContact.organization, {
        shouldTouch: true,
      });
      form.setValue("address", firstContact.address, {
        shouldTouch: true,
      });
      form.setValue("url", firstContact.url, {
        shouldTouch: true,
      });
    }
  };

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      setLoading?.(true);
      if (attorneyContact && attorneyContact.id) {
        await attorneyContactApi.update({
          id: attorneyContact.id.toString() + "/",
          data: {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone: data.phone,
            firm: data.firm,
            address: data.address,
            url: data.url,
            updated_by: user?.user.id,
          },
          token: user?.access,
        });
        toast({
          title: t("common.toast.updated_successfully", {
            resource: t("contacts.title.contact"),
          }),
        });
      } else {
        await attorneyContactApi.create({
          url: "",
          data: {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone: data.phone,
            firm: data.firm,
            address: data.address,
            url: data.url,
            created_by: user?.user.id,
          },
          token: user?.access,
        });
        toast({
          title: t("common.toast.added_successfully", {
            resource: t("contacts.title.contact"),
          }),
        });
        form.reset();
      }
      await refresh?.();
      setOpen(false);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading?.(false);
    }
  }

  useEffect(() => {
    if (attorneyContact) {
      zodKeys(FormSchema).map((field) => {
        form.setValue(
          field as keyof z.infer<typeof FormSchema>,
          attorneyContact[field as keyof z.infer<typeof FormSchema>],
          {
            shouldTouch: true,
          },
        );
        return undefined;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attorneyContact]);

  const buttonAction = attorneyContact?.id
    ? t("common.button.update")
    : t("common.button.add");

  return (
    <Dialog
      open={open}
      onOpenChange={(openValue) => {
        handleClear();
        setOpen(openValue);
      }}
    >
      {!!trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        className="w-full md:w-[500px] max-h-screen overflow-y-auto"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        onEscapeKeyDown={(e) => e.preventDefault()}
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full flex flex-col gap-8"
          >
            <DialogHeader>
              <DialogTitle>
                {buttonAction} {t("common.title.contact")}
              </DialogTitle>
            </DialogHeader>

            <div>
              <p className="mb-2 text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                {t("common.statictext.choose_optional_autofill_vcf")}
              </p>
              <UploadVcardForContact
                fileInputRef={fileInputRef}
                handleClear={handleClear}
                setContacts={handleSetContact}
              />
            </div>

            <FormField
              control={form.control}
              name="first_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("common.label.first_name")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("common.placeholder.first_name")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="last_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("common.label.last_name")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("common.placeholder.last_name")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("common.label.email")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("common.placeholder.email")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>* {t("common.label.phone")}</FormLabel>
                  <FormControl>
                    <PhoneInput
                      placeholder={t("common.placeholder.phone")}
                      {...field}
                      defaultCountry="US"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="firm"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("common.label.firm")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("common.placeholder.firm")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("common.label.address")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("common.placeholder.address")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="url"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("common.label.url")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("common.placeholder.url")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button disabled={loading} type="submit">
                {loading ? t("common.button.loading") : buttonAction}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
